<template>
  <v-card :loading="loadView">
    <v-container v-if="!loadView">
      <v-form
        v-model="isFormValid"
        ref="reversionOp-form"
        id="reversionOp-form"
      >
        <v-row dense>
          <v-row>
            <v-col cols="4" class="pb-2 pt-0 pl-4">
              <PageHeader :title="title" class="pt-0 pb-0" />
            </v-col>
            <v-col cols="8" align="end" class="pt-1 pb-0 pr-1">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="to-right" icon @click="closeModalRevertOp">
                    <v-icon v-bind="attrs" v-on="on">{{ closeIcon }}</v-icon>
                  </v-btn>
                </template>
                <span>Cerrar</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-col cols="12" class="p-0">
            <v-card outlined>
              <v-col cols="12" class="text-right pt-0 pb-0" align="end">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="primary--text"
                      @click.stop="showHeader = !showHeader"
                    >
                      {{ !showHeader ? chevronDownIcon : chevronUpIcon }}
                    </v-icon>
                  </template>
                  <span>{{
                    !showHeader ? "Mostrar cabecera" : "Ocultar cabecera"
                  }}</span>
                </v-tooltip>
              </v-col>
              <v-card-text class="py-1 px-5">
                <v-expand-transition>
                  <v-form
                    v-show="showHeader"
                    v-model="showHeader"
                    ref="header-form"
                    id="header-form"
                  >
                    <v-row dense>
                      <v-col class="p-0 pb-1" cols="12" md="4">
                        <v-autocomplete
                          v-model="empresaSelected"
                          return-object
                          :items="empresas"
                          disabled
                          filled
                          outlined
                          item-value="id"
                          :rules="rules.required"
                          item-text="value"
                          clearable
                          ref="btnComboEmpresas"
                          id="btnComboEmpresas"
                          hide-details="auto"
                          dense
                          label="Empresa"
                        >
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="2" class="p-0 pl-1 pb-1">
                        <v-text-field
                          type="text"
                          outlined
                          disabled
                          filled
                          dense
                          hide-details="auto"
                          ref="codigoProveedor"
                          id="codigoProveedor"
                          label="Código proveedor"
                          clearable
                          v-model.trim="provCod"
                          autocomplete="off"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" class="pt-0 pr-4">
                        <v-row>
                          <v-col cols="12" md="1" class="pt-2 pl-4 pr-0">
                            <v-tooltip top max-width="50%">
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  class="px-0"
                                  v-bind="attrs"
                                  v-on="on"
                                  disabled
                                  filled
                                >
                                  {{ searchIcon }}
                                </v-icon>
                              </template>
                              <span
                                >Buscar proveedor por nombre, CUIT o
                                código</span
                              >
                            </v-tooltip>
                          </v-col>
                          <!-- DATOS PROVEEDOR -->

                          <v-col cols="12" md="11" class="p-0 pb-1">
                            <v-text-field
                              type="text"
                              outlined
                              dense
                              filled
                              disabled
                              :rules="rules.required"
                              label="Proveedor"
                              hide-details=""
                              :loading="proveedoresLoading"
                              v-model.trim="provNom"
                              autocomplete="off"
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" md="3" class="p-0">
                        <v-autocomplete
                          v-model="condicionGananciaSelected"
                          :items="condicionesGanancias"
                          dense
                          outlined
                          return-object
                          hide-details="auto"
                          label="Cond. ganancia"
                          disabled
                          filled
                          item-value="id"
                          item-text="value"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="3" class="p-0 pl-1">
                        <v-autocomplete
                          v-model="conceptoGananciaSelected"
                          :items="conceptosGanancias"
                          dense
                          outlined
                          return-object
                          hide-details="auto"
                          label="Concepto Ganancia"
                          clearable
                          :rules="rules.required"
                          disabled
                          filled
                          item-value="id"
                          item-text="value"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="3" class="p-0 pl-1">
                        <v-autocomplete
                          v-model="condicionIBSelected"
                          :items="condicionesIB"
                          dense
                          outlined
                          :rules="rules.required"
                          return-object
                          hide-details="auto"
                          label="Cond. IIBB"
                          disabled
                          filled
                          item-value="id"
                          item-text="value"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="3" class="p-0 pl-1">
                        <v-autocomplete
                          v-model="conceptoIBSelected"
                          :items="conceptosIB"
                          dense
                          outlined
                          return-object
                          :rules="rules.required"
                          hide-details="auto"
                          label="Concepto IIBB"
                          disabled
                          filled
                          item-value="id"
                          item-text="value"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="6" class="pl-4 pr-3">
                        <v-row>
                          <v-col cols="12" md="6" class="p-0 pt-1">
                            <v-autocomplete
                              v-model="tipoComprobanteSelected"
                              :items="tiposComprobantes"
                              ref="tipoComprobante"
                              outlined
                              return-object
                              clearable
                              disabled
                              filled
                              :loading="tiposComprobantesLoading"
                              :rules="rules.required"
                              item-value="id"
                              item-text="value"
                              dense
                              label="Tipo comprobante"
                              hide-details="auto"
                            >
                            </v-autocomplete>
                          </v-col>
                          <v-col cols="3" class="p-0 px-4">
                            <v-row>
                              <v-col class="p-0 pl-1 pt-1" md="5">
                                <v-text-field
                                  v-model="letraComprobante"
                                  :rules="rules.required"
                                  v-mask="'A'"
                                  dense
                                  outlined
                                  hide-details="auto"
                                  label="Letra"
                                  filled
                                  disabled
                                ></v-text-field>
                              </v-col>
                              <v-col class="p-0 pl-1 pt-1" md="7">
                                <v-text-field
                                  v-model="sucursalComprobante"
                                  v-mask="'#####'"
                                  dense
                                  outlined
                                  hide-details="auto"
                                  label="Sucursal"
                                  disabled
                                  filled
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col class="p-0 pl-1 pr-1 pt-1" cols="4" md="3">
                            <v-text-field
                              v-model="numeroComprobante"
                              v-mask="'#########'"
                              dense
                              outlined
                              hide-details="auto"
                              label="Número"
                              disabled
                              filled
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col class="px-4" cols="6" md="3">
                        <v-row dense>
                          <v-col cols="6" class="pr-0 pb-0">
                            <v-menu
                              ref="fecha"
                              v-model="menuFecha"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="fechaSelected"
                                  label="Fecha"
                                  hint="Formato DD/MM/AAAA"
                                  outlined
                                  autocomplete="not"
                                  hide-details="auto"
                                  dense
                                  disabled
                                  filled
                                  clearable
                                  v-mask="'##/##/####'"
                                  v-bind="attrs"
                                  v-on="on"
                                  :rules="
                                    rules.required.concat(rules.validDate)
                                  "
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="fecha"
                                no-title
                                @change="
                                  fechaSelected = helpers.formatDate(fecha)
                                "
                                @input="menuFecha = false"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="6" class="pr-0 pb-0">
                            <v-menu
                              disabled
                              filled
                              ref="fechaContable"
                              v-model="menuFechaContable"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="fechaContableSelected"
                                  label="Fecha contable"
                                  hint="Formato DD/MM/AAAA"
                                  filled
                                  outlined
                                  autocomplete="not"
                                  disabled
                                  hide-details="auto"
                                  dense
                                  clearable
                                  v-mask="'##/##/####'"
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="fechaContable"
                                no-title
                                @change="
                                  fechaContableSelected =
                                    helpers.formatDate(fechaContable)
                                "
                                @input="menuFechaContable = false"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                        </v-row>
                      </v-col>
                      <!-- Concepto pago -->
                      <v-col cols="12" md="3" class="pt-2 pr-0">
                        <v-text-field
                          v-model="conceptoPago"
                          dense
                          disabled
                          filled
                          outlined
                          hide-details="auto"
                          label="Concepto del pago"
                        ></v-text-field>
                      </v-col>
                      <v-row dense>
                        <v-col cols="12" md="3" class="pr-n2 pl-4 pb-0 pt-1">
                          <v-text-field
                            dense
                            disabled
                            filled
                            outlined
                            v-model="modalidadPago"
                            hide-details="auto"
                            label="Modalidad de pago"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3" class="pr-0 pl-0 pb-0 pt-1">
                          <v-text-field
                            dense
                            disabled
                            filled
                            outlined
                            v-model="tipoValor"
                            hide-details="auto"
                            label="Tipo de valor"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="2" class="p-0 pt-1 pl-1">
                          <v-autocomplete
                            v-if="parametroGeneralMultimoneda"
                            v-model="monedaProvSelected"
                            :items="monedas"
                            return-object
                            disabled
                            filled
                            item-value="id"
                            item-text="value"
                            outlined
                            :rules="rules.required"
                            clearable
                            dense
                            label="Moneda proveedor"
                            hide-details="auto"
                          >
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="2" class="p-0 pt-1 pl-1">
                          <v-autocomplete
                            v-if="parametroGeneralMultimoneda"
                            v-model="monedaOPSelected"
                            :items="monedas"
                            return-object
                            disabled
                            filled
                            item-value="id"
                            item-text="value"
                            outlined
                            :rules="rules.required"
                            clearable
                            dense
                            label="Moneda OP"
                            hide-details="auto"
                          >
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="2" class="pt-1 pr-4 pb-0">
                          <v-text-field
                            v-if="showCotizacion && parametroGeneralMultimoneda"
                            dense
                            v-model="cotizacion"
                            outlined
                            disabled
                            filled
                            hide-details="auto"
                            label="Cotización"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-row>
                  </v-form>
                </v-expand-transition>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <!-- Retenciones y Valores -->
        <v-row dense class="pt-3">
          <v-col cols="12" class="p-0 elevation-2">
            <v-card outlined>
              <v-row>
                <v-col cols="6" class="pb-2 pl-8">
                  <span class="primary--text font-weight-medium"
                    >Retenciones y Valores</span
                  >
                </v-col>
              </v-row>
              <v-card-text class="py-1">
                <v-data-table
                  :headers="filteredHeadersRet"
                  :items="retencionesItems"
                  class="elevation-1"
                  no-data-text="No hay retenciones y valores"
                  dense
                  item-key="mpValId"
                  show-select
                  v-model="valoresSelected"
                  @click:row="selectWhenClickRow($event)"
                  @input="recalcularImputacion"
                  :hide-default-footer="retencionesItems.length <= 10"
                >
                  <template v-slot:[`header.data-table-select`]>
                    <v-simple-checkbox
                      v-if="!opAnulada"
                      color="primary"
                      :ripple="false"
                      v-model="selectAll"
                      @click="selectAllItems()"
                    />
                  </template>
                  <template
                    v-slot:[`item.data-table-select`]="{
                      isSelected,
                      select,
                      item,
                    }"
                  >
                    <v-tooltip left :disabled="!item.esPagoRet">
                      <template v-slot:activator="{ on, attrs }">
                        <v-simple-checkbox
                          :value="isSelected"
                          color="primary"
                          v-bind="attrs"
                          v-on="on"
                          :disabled="opAnulada"
                          @input="select($event)"
                          :ripple="false"
                        ></v-simple-checkbox>
                      </template>
                      <span v-if="item.esPagoRet"> Es pago retenciones</span>
                    </v-tooltip>
                  </template>
                  <template v-slot:[`item.imp`]="{ item }">
                    <span>
                      {{ helpers.floatToMoneyString(item.imp, "", true) }}
                    </span>
                  </template>
                  <template v-slot:[`item.fecha`]="{ item }">
                    <v-menu
                      v-if="
                        item.fecha !== null &&
                        valoresSelected.find(
                          (x) => x.mpValId == item.mpValId
                        ) &&
                        !opAnulada
                      "
                      ref="fecha"
                      v-model="item.menuFecha"
                      transition="scale-transition"
                      :close-on-content-click="false"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="item.fecha"
                          style="
                            max-width: 99px;
                            font-size: 14px;
                            width: 120px;
                            margin-top: 2px;
                            margin-bottom: 2px;
                            display: inline-block;
                          "
                          @blur="
                            item.fechaC = helpers.parseDateToIso(item.fecha)
                          "
                          outlined
                          hide-details="auto"
                          dense
                          v-mask="'##/##/####'"
                          v-bind="attrs"
                          v-on="on"
                          :rules="
                            rules.required.concat(
                              rules.validDate,
                              rules.validDateRange(
                                fecha,
                                helpers.parseDateToIso(item.fecha)
                              )
                            )
                          "
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="item.fechaC"
                        no-title
                        @change="item.fecha = helpers.formatDate(item.fechaC)"
                        @input="item.menuFecha = false"
                      ></v-date-picker>
                    </v-menu>
                    <span v-else>
                      {{ item.fecha }}
                    </span>
                  </template>
                  <template v-slot:[`item.importeComp`]="{ item }">
                    <span>
                      {{
                        helpers.floatToMoneyString(
                          parseFloat(item.importeComp),
                          "",
                          true
                        )
                      }}
                    </span>
                  </template>
                </v-data-table>
              </v-card-text>
              <v-card-actions>
                <v-col
                  v-if="showCotizacion && parametroGeneralMultimoneda"
                  cols="8"
                  align="end"
                >
                  <h6>Totales valores:</h6>
                </v-col>
                <v-col cols="2" align="end" class="text-right pr-2 p-0">
                  <currency-input
                    v-if="parametroGeneralMultimoneda && showCotizacion"
                    :label="`Total (${monedaExtranjeraNom})`"
                    v-model="totalValoresMonedaExtranjera"
                    readonly
                    filled
                    disabled
                    dense
                    outlined
                    :rules="[]"
                    :options="currencyOptions"
                    hideDetails="true"
                  ></currency-input>
                </v-col>
                <v-col
                  v-if="!showCotizacion || !parametroGeneralMultimoneda"
                  cols="8"
                  align="end"
                  class="text-right"
                >
                  <h6>Total valores:</h6>
                </v-col>
                <v-col cols="2" align="end" class="text-right pr-2 p-0">
                  <currency-input
                    :label="`Total (${monedaPorDefectoNom})`"
                    v-model="totalValores"
                    readonly
                    disabled
                    filled
                    dense
                    outlined
                    :rules="[]"
                    :options="currencyOptions"
                    :hideDetails="'true'"
                  ></currency-input>
                </v-col>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <!-- Comprobantes a cancelar -->
        <v-row dense class="pt-3">
          <v-col cols="12" class="p-0">
            <v-card outlined class="pb-3 elevation-2">
              <v-row>
                <v-col cols="12" md="6" class="pt-2 pb-0 pl-6">
                  <span class="primary--text font-weight-medium py-0 pl-2"
                    >Comprobantes cancelados</span
                  >
                </v-col>
              </v-row>
              <v-card-text class="py-1">
                <v-data-table
                  :headers="filteredHeadersComp"
                  :items="comprobantes"
                  :search="search"
                  dense
                  no-data-text="No hay comprobantes cancelados"
                  :hide-default-footer="comprobantes.length <= 10"
                  class="elevation-1 custom-data-table"
                >
                  <template v-slot:[`item.total`]="{ item }">
                    <span>{{
                      helpers.floatToMoneyString(item.total, "", true)
                    }}</span>
                  </template>
                  <template v-slot:[`item.saldo`]="{ item }">
                    <span>{{
                      helpers.floatToMoneyString(item.saldo, "", false)
                    }}</span>
                  </template>
                  <template v-slot:[`item.saldoComp`]="{ item }">
                    <span>{{
                      helpers.floatToMoneyString(item.saldoComp, "", false)
                    }}</span>
                  </template>
                  <template v-slot:[`item.cancelado`]="{ item }">
                    <span>{{
                      helpers.floatToMoneyString(item.saldo, "", true)
                    }}</span>
                  </template>
                  <template v-slot:[`item.cancela`]="{ item }">
                    <span v-if="monedaProvDistintaDefecto">{{
                      helpers.floatToMoneyString(item.cancelaComp, "", true)
                    }}</span>
                    <span v-if="!monedaProvDistintaDefecto">{{
                      helpers.floatToMoneyString(item.cancela, "", true)
                    }}</span>
                  </template>
                  <template v-slot:[`item.cotizacionComprobante`]="{ item }">
                    <span>{{
                      helpers.floatToMoneyString(
                        item.cotizacionComprobante,
                        "",
                        true
                      )
                    }}</span>
                  </template>
                  <template v-slot:[`item.diferenciaCambio`]="{ item }">
                    <v-tooltip top max-width="20rem">
                      <template v-slot:activator="{ on, attrs }">
                        <span v-on="on" v-bind="attrs"
                          >{{
                            helpers.floatToMoneyString(
                              item.cancelaComp * cotizacion -
                                item.cancelaComp * item.cotizacionComprobante,
                              siglaMonedaPorDefecto,
                              false
                            )
                          }}
                          <v-icon class="mt-0" small>
                            {{ infoIcon }}
                          </v-icon></span
                        >
                      </template>
                      <v-row>
                        <v-col cols="12" class="p-0">
                          <span>
                            (Cancela * Cotiz. O.P) - (Cancela * Cotiz. Fact.)
                          </span>
                        </v-col>
                        <v-col cols="12" class="p-0">
                          <span>
                            {{
                              `${helpers.floatToMoneyString(
                                item.cancelaComp,
                                "",
                                true
                              )} * ${helpers.floatToMoneyString(
                                parseFloat(cotizacion),
                                "",
                                true
                              )} - ${helpers.floatToMoneyString(
                                item.cancelaComp,
                                "",
                                true
                              )} * ${
                                item.cotizacionComprobante
                              } = ${helpers.floatToMoneyString(
                                item.cancelaComp * cotizacion -
                                  item.cancelaComp * item.cotizacionComprobante,
                                "",
                                true
                              )}`
                            }}
                          </span>
                        </v-col>
                      </v-row>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-card-text>
              <v-card-actions class="pb-0">
                <v-col
                  v-if="showCotizacion && parametroGeneralMultimoneda"
                  cols="8"
                  align="end"
                >
                  <h6>Totales a imputar:</h6>
                </v-col>
                <v-col cols="2" align="end" class="text-right pr-2 pb-0 p-0">
                  <currency-input
                    v-if="parametroGeneralMultimoneda && showCotizacion"
                    :label="`Total (${monedaExtranjeraNom})`"
                    v-model="totalEnMonedaExtranjera"
                    readonly
                    filled
                    disabled
                    dense
                    outlined
                    :rules="[]"
                    :options="currencyOptions"
                    :hideDetails="'true'"
                  ></currency-input>
                </v-col>
                <v-col
                  v-if="!showCotizacion || !parametroGeneralMultimoneda"
                  cols="8"
                  align="end"
                  class="text-right"
                >
                  <h6>Total a imputar:</h6>
                </v-col>
                <v-col cols="2" align="end" class="text-right pr-2 pb-0 p-0">
                  <currency-input
                    :label="`Total (${monedaPorDefectoNom})`"
                    v-model="totalEnMonedaPorDefecto"
                    readonly
                    disabled
                    filled
                    dense
                    outlined
                    :rules="[]"
                    :options="currencyOptions"
                    :hideDetails="'true'"
                  ></currency-input>
                </v-col>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-4 pl-0 pb-1" cols="4">
            <v-autocomplete
              v-model="tipoComprobanteAnulacionSelected"
              return-object
              :items="tiposComprobantesAnulacion"
              outlined
              item-value="id"
              :rules="rules.required"
              item-text="value"
              ref="comboTipoCompAnula"
              :filled="opAnulada"
              :disabled="opAnulada"
              id="comboTipoCompAnula"
              hide-details="auto"
              dense
              label="Tipo comprobante anulación"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="2" class="pr-0 pb-0 pt-4">
            <v-menu
              ref="fecha"
              v-model="menuFechaReversion"
              transition="scale-transition"
              :close-on-content-click="false"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="fechaReversionSelected"
                  label="Fecha"
                  @blur="
                    fechaReversion = helpers.parseDateToIso(
                      fechaReversionSelected
                    )
                  "
                  :disabled="!tCompAnulacionEditaFecha || opAnulada"
                  :filled="!tCompAnulacionEditaFecha || opAnulada"
                  outlined
                  hide-details="true"
                  dense
                  v-mask="'##/##/####'"
                  v-bind="attrs"
                  v-on="on"
                  :rules="rules.required.concat(rules.validDate)"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fechaReversion"
                no-title
                @change="
                  fechaReversionSelected = helpers.formatDate(fechaReversion)
                "
                @input="menuFechaReversion = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="2" class="pr-0 pt-4 pb-0">
            <v-menu
              ref="fechaContable"
              :close-on-content-click="false"
              v-model="menuFechaContableReversion"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="fechaContableReversionSelected"
                  label="Fecha contable"
                  @blur="
                    fechaContableReversion = helpers.parseDateToIso(
                      fechaContableReversionSelected
                    )
                  "
                  outlined
                  hide-details="true"
                  dense
                  :disabled="!tCompAnulacionEditaFecha || opAnulada"
                  :filled="!tCompAnulacionEditaFecha || opAnulada"
                  v-mask="'##/##/####'"
                  v-bind="attrs"
                  v-on="on"
                  :rules="rules.required.concat(rules.validDate)"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fechaContableReversion"
                no-title
                @change="
                  fechaContableReversionSelected = helpers.formatDate(
                    fechaContableReversion
                  )
                "
                @input="menuFechaContableReversion = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col md="4" class="pt-4 pr-0">
            <v-text-field
              v-model="obsReversion"
              dense
              outlined
              :filled="opAnulada"
              :disabled="opAnulada"
              hide-details="auto"
              label="Observaciones de la reversión"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="text-right pr-0 pt-1" align="end">
            <v-btn
              form="reversionOp-form"
              :loading="loadingSaveBtn"
              :disabled="!isFormValid || opAnulada"
              color="primary"
              @click="saveReversionOp()"
              >Grabar reversión</v-btn
            >
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </v-card>
</template>

<script>
import enums from "@/utils/enums/index.js";
import PageHeader from "@/components/ui/PageHeader";
import { mask } from "vue-the-mask";
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import helpers from "@/utils/helpers.js";
import ConfirmDialog from "@/components/shared/ConfirmDialog";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";
import Loader from "@/deprecated/components/Loader/Loader";

export default {
  name: "RevertirOrdenDePago",
  directives: { mask },
  components: { CurrencyInput, ConfirmDialog, Loader, PageHeader },
  props: {
    movProvId: {
      type: Number,
      require: true,
      default: 1,
    },
    opAnulada: {
      type: Boolean,
      require: true,
      default: false,
    },
  },
  data() {
    return {
      enums: enums,
      rules: rules,
      helpers: helpers,
      searchIcon: enums.icons.SEARCH,
      search: null,
      infoIcon: enums.icons.SNB_INFO,
      closeIcon: enums.icons.CLOSE,
      chevronDownIcon: enums.icons.CHEVRON_DOWN,
      chevronUpIcon: enums.icons.CHEVRON_UP,
      showHeader: true,
      isFormValid: false,
      loadView: true,
      loadingSaveBtn: false,
      valoresSelected: [],
      selectAll: false,
      obsReversion: "",
      valoresSelectedPrev: [],
      // fechas
      fecha: null,
      menuFecha: null,
      fechaSelected: null,
      fechaContable: null,
      menuFechaContable: null,
      menuFechaReversion: null,
      menuFechaContableReversion: null,
      fechaContableSelected: null,
      fechaContableReversionSelected: helpers.formatDate(
        new Date(new Date()).toISOString().substr(0, 10)
      ),
      fechaContableReversion: new Date().toISOString().substr(0, 10),
      fechaReversion: new Date().toISOString().substr(0, 10),
      fechaReversionSelected: helpers.formatDate(
        new Date(new Date()).toISOString().substr(0, 10)
      ),
      currencyOptions: {
        locale: "nl-NL",
        currency: "USD",
        currencyDisplay: "narrowSymbol",
        valueRange: {
          min: 0,
          max: 999999999999999,
        },
        precision: 2,
        hideCurrencySymbolOnFocus: false,
        hideGroupingSeparatorOnFocus: false,
        hideNegligibleDecimalDigitsOnFocus: false,
        autoDecimalDigits: false,
        exportValueAsInteger: false,
        autoSign: true,
        useGrouping: true,
      },
      title: "Revertir orden de pago",
      empresas: [],
      empresaSelected: null,
      condicionesGanancias: [],
      conceptosGanancias: [],
      condicionesIB: [],
      conceptosIB: [],
      conceptoPago: "",
      proveedores: [],
      tCompSigno: 1,
      condicionGananciaSelected: null,
      conceptoGananciaSelected: null,
      condicionIBSelected: null,
      conceptoIBSelected: null,
      modalidadPago: null,
      tIvaId: null,
      tipoValor: null,
      provCod: null,
      proveedorSelected: null,
      proveedoresLoading: null,
      provId: null,
      provNom: null,
      cuit: null,
      monedaOPSelected: null,
      monedaProvSelected: null,
      monedaPorDefecto: null,
      siglaMonedaPorDefecto: null,
      monedaPorDefectoNom: null,
      letraComprobante: "",
      sucursalComprobante: "",
      numeroComprobante: "",
      parametroGeneralMultimoneda: false,
      tipoComprobanteSelected: null,
      tieneNumeradorAutomatico: false,
      tiposComprobantesLoading: false,
      tipoComprobanteAnulacionSelected: null,
      tiposComprobantesAnulacion: [],
      tiposComprobantes: [],
      monedas: [],
      cotizacion: 1,
      totalValores: 0,
      totalNetoComprobantes: 0,
      totalNetoComprobantesComp: 0,
      totalEnMonedaPorDefecto: 0.0,
      totalEnMonedaExtranjera: 0.0,
      totalValoresMonedaExtranjera: 0,
      tCompAnulacionEditaFecha: false,
      headersComprobantes: [
        { text: "Comprobante", value: "nroComp", sortable: false },
        { text: "Fecha", value: "fechaComprobanteFormatted", sortable: false },
        {
          text: "Vencimiento",
          value: "fechaVencimientoFormatted",
          sortable: false,
        },
        {
          text: "Total",
          align: "end",
          value: "totalFormatted",
          sortable: false,
        },
        {
          text: "Cancelado",
          align: "end",
          value: "canceladoFormatted",
          sortable: false,
        },
        {
          text: "Saldo",
          align: "end",
          value: "saldoComp",
          sortable: false,
        },
        {
          text: "Saldo",
          align: "end",
          value: "saldo",
          sortable: false,
        },
        { text: "Cancela", align: "end", value: "cancela", sortable: false },
        {
          text: "Dif. Cambio",
          align: "end",
          value: "diferenciaCambio",
          sortable: false,
        },
      ],
      comprobantes: [],
      headersRetenciones: [
        { text: "Tipo valor", value: "tvNombre", sortable: false },
        { text: "Banco", sortable: false, value: "bancoNombre" },
        { text: "Cuenta", sortable: false, value: "bctaNombre" },
        { text: "Fecha", sortable: false, value: "fecha" },
        { text: "Número", sortable: false, value: "numero" },
        {
          text: "Importe dólar",
          align: "end",
          sortable: false,
          value: "importeComp",
        },
        { text: "Importe pesos", align: "end", sortable: false, value: "imp" },
      ],
      retencionesItems: [],
      optionCode: enums.webSiteOptions.CARGA_ORDEN_PAGO_COMP_PROV,
    };
  },
  computed: {
    filteredHeadersComp() {
      let monedaProvDistinta = this.monedaProvDistintaDefecto;
      return this.headersComprobantes.filter((header) => {
        //usamos saldoComp solo cuando la moneda del proveedor es distinta de la porDefecto.
        if (monedaProvDistinta && header.value === "saldo") return false;
        if (
          !monedaProvDistinta &&
          (header.value === "diferenciaCambio" || header.value === "saldoComp")
        )
          return false;

        return true;
      });
    },
    filteredHeadersRet() {
      let monedaProvDistinta =
        this.monedaProvDistintaDefecto || this.showCotizacion;
      return this.headersRetenciones.filter((header) => {
        if (monedaProvDistinta && header.value == "importeComp") {
          header.text = `Importe ${this.monedaExtranjeraNom?.toLowerCase()}`;
        }
        if (!monedaProvDistinta && header.value === "importeComp") {
          return false;
        }
        return true;
      });
    },
    showCotizacion() {
      let monedaOpDistinta = true;
      if (this.monedaOPSelected?.id == undefined) {
        monedaOpDistinta =
          this.monedaOPSelected != null &&
          this.monedaOPSelected != this.monedaPorDefecto;
      } else {
        monedaOpDistinta =
          this.monedaOPSelected != null &&
          this.monedaOPSelected?.id != this.monedaPorDefecto;
      }
      let monedaProvDistinta = this.monedaProvDistintaDefecto;
      return (
        this.monedaPorDefecto != null &&
        (monedaOpDistinta || monedaProvDistinta)
      );
    },
    monedaProvDistintaDefecto() {
      return (
        this.monedaProvSelected != null &&
        this.monedaProvSelected?.id !== this.monedaPorDefecto
      );
    },
    monedaExtranjeraNom() {
      let result = "";

      if (
        this.monedaOPSelected != null &&
        this.monedaOPSelected?.id != this.monedaPorDefecto
      ) {
        result = this.monedaOPSelected?.value;
      } else if (this.monedaProvDistintaDefecto) {
        result = this.monedaProvSelected.value;
      }
      return result;
    },
  },
  watch: {
    loadView(val) {
      if (!val) {
        this.$store.dispatch("user/changeAppLoading", {
          status: false,
          text: "",
          opaque: false,
        });
      }
    },
  },
  async created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: true,
      text: "",
      opaque: false,
    });
    await this.setCombos();
    await this.setDatosOpEdit();
    if (this.opAnulada) {
      this.setAlert({
        type: "warning",
        message: "La orden de pago ya fue revertida",
      });
    }
  },
  mounted() {},
  methods: {
    ...mapActions({
      getCondicionesIIBB: "afiliaciones/getCondicionesIIBB",
      getConceptosIIBB: "afiliaciones/getConceptosIIBB",
      getCondicionesGanancias: "taxonomy/getCondicionesGanancias",
      getConceptosGanancias: "taxonomy/getConceptosGanancias",
      getTiposComprobanteCargaOrdenesDePago:
        "proveedores/getTiposComprobanteCargaOrdenesDePago",
      getTipoCompsAnulacionOp: "proveedores/getTipoCompsAnulacionOp",
      getEntidadesFacturantes: "proveedores/getEntidadesFacturantes",
      getTipoCompTieneNumAutomatico:
        "proveedores/getTipoCompTieneNumAutomatico",
      getMonedasTaxonomy: "AdministracionSistema/getMonedasTaxonomy",
      getTipoComprobanteProveedorById:
        "proveedores/getTipoComprobanteProveedorById",
      grabarOp: "proveedores/grabarOpNc",
      getOpDetalleCompletoProv: "proveedores/getOpDetalleCompletoProv",
      getParametrosGeneralesProveedor:
        "proveedores/getParametrosGeneralesProveedorCargaFactura",
      getConceptosCondicionesProveedor:
        "proveedores/getConceptosCondicionesProveedor",
      getMonedaDeProveedor: "proveedores/getMonedaDeProveedor",
      getProveedoresByCod: "afiliaciones/getProveedoresByCod",
      saveAnulacionOp: "proveedores/saveAnulacionOp",
      setAlert: "user/setAlert",
    }),
    async setDatosOpEdit() {
      try {
        const response = await this.getOpDetalleCompletoProv(this.movProvId);
        let monedaIdComp = 1;
        if (response) {
          monedaIdComp = response.monedaIdComp;
          await this.setEmpresaProveedor(response.entFacId, response.provCod);
          this.tipoComprobanteSelected = this.tiposComprobantes.find(
            (x) => x.id == response.tipoCompId
          );
          await this.setDatosTipoComprobante();
          this.letraComprobante = response.letra.toString();
          this.sucursalComprobante = response.sucursal.toString();
          this.numeroComprobante = response.numero.toString();
          this.fecha = helpers.formatDate(response.fecha);
          this.cotizacion = response.cotizacion;
          this.conceptoIBSelected = this.conceptosIB.find(
            (x) => x?.id == response.concIBId
          );
          // fecha
          this.fechaSelected = response.fechaString;
          let partesFechaToFomat = this.fechaSelected.split("/");
          this.fecha = `${partesFechaToFomat[2]}-${partesFechaToFomat[1]}-${partesFechaToFomat[0]}`;
          // fecha contable
          this.fechaContableSelected = response.fechaContString;
          let partesFechaContableToFomat =
            this.fechaContableSelected.split("/");
          this.fechaContable = `${partesFechaContableToFomat[2]}-${partesFechaContableToFomat[1]}-${partesFechaContableToFomat[0]}`;
          this.conceptoPago = response.obs;
          this.retencionesItems = response.listaValores;
          this.retencionesItems = this.retencionesItems.map((item) => {
            if (item.fecha !== null) {
              let date = new Date(item.fecha);
              item.fecha = date.toLocaleDateString("es-ES", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              });
            }
            if (!item.importeComp)
              item.importeComp = parseFloat(
                (item.imp / this.cotizacion).toFixed(2)
              );
            return item;
          });
          setTimeout(() => {
            this.totalNetoComprobantes = response.neto;
            this.totalNetoComprobantesComp = response.netoComp;
            this.totalEnMonedaPorDefecto = response.total;
            this.totalEnMonedaExtranjera = response?.totalComp;
            this.comprobantes = response.listaComprobante;
            this.totalValores = response.totalValores;
            this.totalValoresMonedaExtranjera = response.totalValoresComp;

            //seteamos los cancela originales para luego ver los afectados
            this.comprobantes.map(
              (item) => (item.cancelaOriginal = item.cancela)
            );
          }, 100);
          await this.setTipoCompAnulacion();
          if (this.opAnulada) {
            this.valoresSelected = response.listaValores.filter(
              (x) => x.mpIdAnulaVarios !== null && x.mpIdAnulaVarios > 0
            );
          }
        }
        this.monedaOPSelected = this.monedas.find((x) => x.id == monedaIdComp);
      } catch {}
      this.loadView = false;
    },
    async setTipoCompAnulacion() {
      this.tiposComprobantesAnulacion = await this.getTipoCompsAnulacionOp({
        entFacId: this.empresaSelected.id,
        tCompIdOp: this.tipoComprobanteSelected.id,
      });
      if (this.tiposComprobantesAnulacion.length === 1)
        this.tipoComprobanteAnulacionSelected =
          this.tiposComprobantesAnulacion[0];

      this.tCompAnulacionEditaFecha =
        this.tipoComprobanteAnulacionSelected.bool;
    },
    recalcularImputacion() {
      const nuevosSeleccionados = this.valoresSelected.filter(
        (item) => !this.valoresSelectedPrev.includes(item)
      );
      const deseleccionados = this.valoresSelectedPrev.filter(
        (item) => !this.valoresSelected.includes(item)
      );
      if (this.monedaProvDistintaDefecto) {
        // Manejo de los ítems seleccionados
        if (nuevosSeleccionados.length > 0) {
          nuevosSeleccionados.forEach((item) => {
            if (this.monedaProvDistintaDefecto)
              this.totalEnMonedaExtranjera -= parseFloat(item.importeComp);
          });
        }
        // Manejo de los ítems deseleccionados
        if (deseleccionados.length > 0) {
          deseleccionados.forEach((item) => {
            if (this.monedaProvDistintaDefecto)
              this.totalEnMonedaExtranjera += parseFloat(item.importeComp);
          });
        }

        this.totalEnMonedaPorDefecto =
          this.totalEnMonedaExtranjera * this.cotizacion;
      } else {
        // Manejo de los ítems seleccionados
        if (nuevosSeleccionados.length > 0) {
          nuevosSeleccionados.forEach((item) => {
            this.totalEnMonedaPorDefecto -= parseFloat(item.imp);
          });
        }
        // Manejo de los ítems deseleccionados
        if (deseleccionados.length > 0) {
          deseleccionados.forEach((item) => {
            this.totalEnMonedaPorDefecto += parseFloat(item.imp);
          });
        }
        this.totalEnMonedaExtranjera =
          this.totalEnMonedaPorDefecto / this.cotizacion;
      }
      // Actualiza el array anterior
      this.valoresSelectedPrev = [...this.valoresSelected];

      this.distribuirComprobantes();
    },
    distribuirComprobantes() {
      let saldoDisponible = this.totalEnMonedaPorDefecto;

      let saldoDisponibleComp = this.totalEnMonedaExtranjera;

      this.comprobantes.forEach((comprobante) => {
        //primer caso: cancelo en base a pesos
        if (!this.monedaProvDistintaDefecto) {
          // Obtener el saldo pendiente del comprobante
          let saldoComprobante = comprobante.saldo;
          // Si el saldo disponible es mayor o igual que el saldo del comprobante, cancelo el comprobante completamente
          if (saldoDisponible >= saldoComprobante) {
            comprobante.cancela = saldoComprobante; // Cancelo el saldo completo
            saldoDisponible -= saldoComprobante; // Resto el saldo disponible
          } else {
            // Si no hay suficiente saldo para cancelar todo el comprobante, cancelo solo la parte que queda del saldo disponible
            comprobante.cancela = saldoDisponible; // Cancelo lo que queda del saldo disponible
            saldoDisponible = 0; // Ya no queda saldo disponible
          }

          //segundo caso: los mismo pero cancelo en base a dolares
        } else if (this.monedaProvDistintaDefecto) {
          let saldoCompComprobante = comprobante.saldoComp;

          if (saldoDisponibleComp >= saldoCompComprobante) {
            comprobante.cancelaComp = saldoCompComprobante;
            saldoDisponibleComp -= saldoCompComprobante;
          } else {
            comprobante.cancelaComp = saldoDisponibleComp;
            saldoDisponibleComp = 0;
          }

          //luego de distribuir calculamos el cancela en base al cancelaComp y la cotizacion
          // if (comprobante.cancelaComp !== 0)
          //   comprobante.cancela = comprobante.cancelaComp * this.cotizacion;
          // else comprobante.cancela = 0;
        }
      });
      if (this.monedaProvDistintaDefecto) {
        this.comprobantes.forEach((x) => {
          if (x.cotizacionComprobante < this.cotizacion)
            x.cancela = x.cancelaComp * x.cotizacionComprobante;
          else x.cancela = x.cancelaComp * this.cotizacion;
        });
      }
      this.calcularDiferenciaCambio(this.cotizacion);
    },
    async setEmpresaProveedor(entFacId, provCod) {
      if (entFacId != null) {
        this.empresaSelected = this.empresas.find((x) => x.id == entFacId);
        this.getTiposComprobantes();
      }
      if (provCod != null) {
        this.provCod = provCod;
        await this.buscarProveedor();
      }
    },
    selectWhenClickRow(item) {
      if (!this.opAnulada) {
        const isSelected = this.valoresSelected.some(
          (selectedItem) => selectedItem.mpValId === item.mpValId
        );
        if (!isSelected) this.valoresSelected.push(item);
        else
          this.valoresSelected = this.valoresSelected.filter((x) => x !== item);
      }
    },
    async buscarProveedor() {
      if (
        this.provCod !== null &&
        this.provCod !== undefined &&
        this.provCod !== ""
      ) {
        try {
          this.proveedoresLoading = true;
          this.proveedores = await this.getProveedoresByCod(this.provCod);
          if (this.proveedores.length === 0) {
            this.setAlert({
              type: "warning",
              message: "Proveedor inexistente",
            });
            this.provId = null;
            this.proveedorSelected = null;
            this.provNom = null;
            this.proveedoresLoading = false;
            return;
          }
          this.proveedoresLoading = false;
          this.proveedorSelected = this.proveedores[0];
          this.provId = this.proveedorSelected.id;
          this.provNom = this.proveedorSelected.value1.toUpperCase();
          this.cuit = this.proveedorSelected.cuit;
          this.comprobantes = [];
          this.retencionesItems = [];
          await this.getMonedaProveedor();
          await this.setCondicionesConceptosProveedor();
          await this.getLetraComprobanteByProvEntFacId();
        } catch (error) {
          this.proveedoresLoading = false;
          this.proveedorSelected = null;
          this.provId = null;
        }
      } else {
        this.proveedorSelected = null;
        this.provId = null;
        this.provNom = null;
        this.condicionGananciaSelected = null;
        this.condicionIBSelected = null;
        this.monedaProvSelected = null;
        this.modalidadPago = null;
        this.tipoValor = null;
        this.comprobantes = [];
        this.cotizacion = 1;
        this.retencionesItems = [];
      }
    },
    async getTiposComprobantes() {
      if (this.empresaSelected != null) {
        this.tiposComprobantesLoading = true;
        this.tiposComprobantes =
          await this.getTiposComprobanteCargaOrdenesDePago(
            this.empresaSelected.id
          );
        this.tiposComprobantesLoading = false;
      }
    },
    async getLetraComprobanteByProvEntFacId() {
      if (this.entFacId != null && this.provId != null) {
        const letraResponse = await this.getLetraTipoCompProveedor({
          entFacId: this.entFacId,
          provId: this.provId,
        });
        if (letraResponse != null && letraResponse.length > 0)
          this.letraComprobante = letraResponse;
      }
    },
    async getMonedaProveedor() {
      if (this.provId != null) {
        const response = await this.getMonedaDeProveedor(this.provId);
        if (response != null) {
          this.monedaProvSelected = this.monedas.find(
            (x) => x.id == response.id
          );
        }
      }
    },
    selectAllItems() {
      if (this.selectAll) this.valoresSelected = this.retencionesItems;
      else this.valoresSelected = [];
    },
    async setDatosTipoComprobante() {
      if (this.tipoComprobanteSelected != null) {
        const response = await this.getTipoComprobanteProveedorById(
          this.tipoComprobanteSelected.id
        );
        let datosTipoComprobante = response;
        this.tCompSigno = datosTipoComprobante.tCompSigno;
        this.tieneNumeradorAutomatico = datosTipoComprobante.numAuto;
        if (datosTipoComprobante.numAutoLetra.trim().length > 0)
          this.letraComprobante = datosTipoComprobante.numAutoLetra.toString();

        if (datosTipoComprobante.numAutoSuc)
          this.sucursalComprobante = datosTipoComprobante.numAutoSuc.toString();

        await this.getTipoCompNumAutomatico(
          this.tipoComprobanteSelected.id,
          datosTipoComprobante.numAutoLetra,
          datosTipoComprobante.numAutoSuc
        );
      } else {
        this.letraComprobante = "";
        this.sucursalComprobante = "";
        this.numeroComprobante = "";
        this.tieneNumeradorAutomatico = false;
      }
    },
    async getTipoCompNumAutomatico(tCompId, letra, sucursal) {
      if (letra && tCompId && sucursal) {
        this.numeroComprobante = "0";

        if (tCompId != null && letra != null && sucursal != null) {
          this.tieneNumeradorAutomatico =
            await this.getTipoCompTieneNumAutomatico({
              tCompId: tCompId?.id ?? tCompId,
              numAutoSuc: sucursal,
              numAutoLetra: letra,
            });
        } else this.tieneNumeradorAutomatico = false;
      }
    },
    async saveReversionOp() {
      this.loadingSaveBtn = true;
      if (
        this.retencionesItems.some((x) => x.esPagoRet) &&
        this.valoresSelected.length !== this.retencionesItems.length
      ) {
        this.setAlert({
          type: "warning",
          message: `Existe un valor con pago de retenciones, se debe revertir toda la orden de pago.`,
        });
        return;
      }
      this.comprobantes.forEach((item) => {
        if (item.cancelaOriginal != item.cancela)
          this.obsReversion += ` - Comp Afec: ${item.nroComp}`;
      });

      const dataReversionOp = {
        obs: this.obsReversion,
        sesion: 0,
        mProvIdOpParaAnulacion: this.movProvId,
        mProv_id: this.movProvId,
        fecha: this.fecha,
        fechaVenc: null,
        fecContable: this.fechaContable,
        tCompId: this.tipoComprobanteAnulacionSelected.id,
        provId: this.provId,
        nombre: this.proveedorSelected.value1,
        cuit: this.proveedorSelected.cuit,
        direccion: this.proveedorSelected.domicilio,
        letra: this.tipoComprobanteAnulacionSelected.numAutoLetra,
        suc: this.tipoComprobanteAnulacionSelected.numAutoSuc,
        numero: parseInt(this.numeroComprobante),
        tIvaId: this.tIvaId,
        neto: this.totalNetoComprobantes,
        iva: 0,
        percepcionIva: 0,
        percepcionIb: 0,
        impuestosInternos: 0,
        otrosConceptos: 0,
        total: this.valoresSelected.reduce(
          (acc, item) => parseFloat((acc + item.imp).toFixed(2)),
          0
        ),
        monedaId: this.monedaPorDefecto,
        entFacId: this.empresaSelected.id,
        totalComp: this.showCotizacion
          ? this.valoresSelected.reduce(
              (acc, item) => parseFloat((acc + item.importeComp).toFixed(2)),
              0
            )
          : 0,
        signo: this.tCompSigno,
        condGanId: this.condicionGananciaSelected?.id,
        concGanId: this.conceptoGananciaSelected?.id,
        toId: null,
        concIbId: this.conceptoIBSelected?.id,
        condIbId: this.condicionIBSelected?.id,
        periodo: null,
        provIdCdo: null,
        caeCaiProv: null,
        caeCaiProvFecVen: null,
        tEmision: null,
        netoComp:
          this.monedaOPSelected.id !== this.monedaPorDefecto ||
          this.monedaProvDistintaDefecto
            ? this.totalNetoComprobantes / this.cotizacion
            : null,
        ivaComp: null,
        percepcionIvaComp: null,
        percepcionIbComp: null,
        impuestosInternosComp: null,
        otrosConceptosComp: null,
        tipoComprobanteDif: "Op",
        tcompTieneNumeradorAutomatico: this.tieneNumeradorAutomatico,
        monedaIdComp:
          this.monedaOPSelected.id == this.monedaPorDefecto &&
          !this.monedaProvDistintaDefecto
            ? null
            : this.monedaOPSelected.id,
        cotizacion: this.cotizacion,
        listaValores: this.valoresSelected.map((item) => {
          if (item.fecha !== null)
            item.fecha = helpers.parseDateToIso(item.fecha);
          return item;
        }),
        listaComprobante: this.comprobantes.filter(
          (x) => x.cancelaOriginal != x.cancela
        ),
        listaPercepcionIB: [],
        listaAlicuota: [],
        listaImputContable: [],
        listaCentrosCostos: [],
      };
      try {
        const response = await this.saveAnulacionOp(dataReversionOp);
        if (response && response.idError == 0) {
          this.setAlert({
            type: "success",
            message: `Orden de pago revertida con éxito. ${response.mensaje}`,
          });
          this.closeModalRevertOp();
        } else {
          this.setAlert({
            type: "error",
            message: `Error revertir orden de pago: ${response.mensaje}`,
          });
        }
      } catch {
        this.valoresSelected.map((item) => {
          if (item.fecha !== undefined && item.fecha !== null)
            item.fecha = helpers.formatDate(item.fecha);
          return item;
        });
      } finally {
        this.loadingSaveBtn = false;
      }
    },
    calcularDiferenciaCambio(cotizacionParam) {
      if (!this.monedaProvDistintaDefecto) {
        this.comprobantes = this.comprobantes.map((comprobante) => {
          // Calcula la diferencia de cambio
          const diferenciaCambio =
            comprobante.cancela * cotizacionParam -
            comprobante.cancela * comprobante.cotizacionComprobante;

          // Retorna un nuevo objeto con el valor actualizado
          return {
            ...comprobante,
            diferenciaCambio: diferenciaCambio,
          };
        });
      } else if (this.monedaProvDistintaDefecto) {
        this.comprobantes = this.comprobantes.map((comprobante) => {
          // Calcula la diferencia de cambio pero en base al cancelaComp
          const diferenciaCambio =
            comprobante.cancelaComp * cotizacionParam -
            comprobante.cancelaComp * comprobante.cotizacionComprobante;

          return {
            ...comprobante,
            diferenciaCambio: diferenciaCambio,
          };
        });
      }
    },
    async setCondicionesConceptosProveedor() {
      if (this.provId != null) {
        const response = await this.getConceptosCondicionesProveedor(
          this.provId
        );
        this.condicionGananciaSelected = this.condicionesGanancias.find(
          (x) => x.id == response.condGanId
        );
        this.conceptoGananciaSelected = this.conceptosGanancias.find(
          (x) => x.id == response.concGanId
        );
        this.condicionIBSelected = this.condicionesIB.find(
          (x) => x.id == response.condIbId
        );
        this.conceptoIBSelected = this.conceptosGanancias.find(
          (x) => x.id == response.concIbId
        );
        this.modalidadPago = response.modalidadPago;
        this.tipoValor = response.tipoValor;
        this.tIvaId = response.iva.id;
        // if (this.tipoValor != null)
        //   this.monedaOPSelected = { id: response.monedaOp };
        // else
        //   this.monedaOPSelected = this.monedas.find(
        //     (x) => x.id == this.monedaPorDefecto
        //   );
      }
    },
    async setCombos() {
      const parametrosGen = await this.getParametrosGeneralesProveedor();
      this.parametroGeneralMultimoneda = parametrosGen.multimoneda;
      this.empresas = await this.getEntidadesFacturantes();
      if (this.empresas.length == 1) this.empresaSelected = this.empresas[0];
      this.monedas = await this.getMonedasTaxonomy("habCarga");
      let monedaPorDefecto = this.monedas.find((x) => x.bool == true);
      this.monedaOPSelected = monedaPorDefecto;
      this.monedaPorDefecto = monedaPorDefecto.id;
      this.monedaPorDefectoNom = monedaPorDefecto.value;
      this.siglaMonedaPorDefecto = this.parametroGeneralMultimoneda
        ? monedaPorDefecto.value2
        : "";
      this.conceptosIB = await this.getConceptosIIBB();
      this.condicionesIB = await this.getCondicionesIIBB();
      this.conceptosGanancias = await this.getConceptosGanancias();
      this.condicionesGanancias = await this.getCondicionesGanancias();
    },
    closeModalRevertOp() {
      this.$emit("toggleModalRevertOp");
    },
  },
};
</script>


<style scoped>
::v-deep .theme--light.v-input--is-disabled input,
.theme--light.v-card--is-disabled input {
  color: rgb(0, 0, 0);
}
</style>