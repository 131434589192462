<template>
  <v-card class="pr-5">
    <v-card-title class="pb-2 pt-1 pl-3">
      <span class="primary--text">{{ title }}</span>
    </v-card-title>
    <v-form
      v-model="isFormValid"
      ref="select-proveedor"
      id="select-proveedor"
      @submit.prevent="selectProveedor()"
    >
      <v-row @keypress.enter="filterProveedores" class="px-5 pb-0">
        <v-col cols="6" sm="12" md="3" class="py-0 px-2">
          <v-text-field
            v-model="proveedorSearchCuit"
            label="CUIT"
            dense
            v-mask="['##-#######-#', '##-########-#']"
            clearable
            :rules="[
              rules.requiredTrim(proveedorSearchCuit),
              rules.minLength(proveedorSearchCuit, 10),
            ]"
            outlined
          >
          </v-text-field>
        </v-col>
        <v-col cols="6" sm="12" md="3" class="py-0 px-2">
          <v-text-field
            v-model="proveedorSearchCodigo"
            label="Código"
            dense
            clearable
            :rules="[
              rules.requiredTrim(proveedorSearchCodigo),
              rules.maxLength(proveedorSearchCodigo, 10),
            ]"
            outlined
          >
          </v-text-field>
        </v-col>

        <v-col cols="6" sm="12" md="4" class="py-0 px-2">
          <v-text-field
            v-model="proveedorSearchNombre"
            label="Razón social / Nombre fantasía"
            dense
            :rules="[
              rules.requiredTrim(proveedorSearchNombre),
              rules.maxLength(proveedorSearchNombre, 50),
            ]"
            clearable
            outlined
          >
          </v-text-field>
        </v-col>
        <v-col class="py-0 pl-0 pr-3">
          <v-btn
            color="primary"
            class="to-right"
            :disabled="!isFormValid"
            @click="filterProveedores"
          >
            Buscar
          </v-btn>
        </v-col>
      </v-row>

      <v-row class="px-4">
        <v-col>
          <v-data-table
            class="elevation-1 pt-0"
            :headers="headers"
            :items="proveedoresResult"
            :loading="loading"
            dense
            item-key="provId"
            :search="search"
            fixed-header
            show-select
            :items-per-page="500"
            :height="dataTableHeight"
            hide-default-footer
            single-select
            v-model="proveedorSelected"
            @input="selectedProveedor()"
          >
            <template v-slot:top>
              <v-toolbar dense class="py-0">
                <v-text-field
                  v-model="search"
                  :append-icon="searchIcon"
                  label="Buscar"
                  single-line
                  hide-details
                ></v-text-field>
              </v-toolbar>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-card-actions class="pr-4">
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal()"> Cerrar </v-btn>
        <v-btn
          color="primary"
          type="submit"
          ref="select-proveedor"
          :disabled="proveedorSelected.length == 0"
        >
          Seleccionar
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import rules from "@/utils/helpers/rules";
import { mapActions } from "vuex";
import { mask } from "vue-the-mask";
import enums from "@/utils/enums/index.js";

export default {
  name: "busquedaProveedor",
  props: {},
  directives: { mask },
  data() {
    return {
      title: "Búsqueda de proveedor",
      rules: rules,
      isFormValid: false,
      filtersApplyed: [],
      proveedorSelected: [],
      proveedoresResult: [],
      dataTableHeight: null,
      searchIcon: enums.icons.SEARCH,
      search: "",

      provId: null,
      proveedorSearchNombre: null,
      proveedorSearchCuit: null,
      proveedorSearchCodigo: null,
      filterApplied: false,
      banderaProveedorSelected: false,
      busquedaProveedor: true,
      loading: false,
      headers: [
        {
          text: "Nombre",
          align: "start",
          sortable: true,
          value: "provNom",
        },
        {
          text: "Nombre fantasía",
          align: "start",
          sortable: true,
          value: "provNombreFantasia",
        },
        {
          text: "Código",
          sortable: false,
          align: "start",
          value: "provCod",
        },
        {
          text: "CUIT",
          align: "start",
          sortable: false,
          value: "cuit",
        },
      ],
    };
  },
  watch: {
    proveedoresResult(val) {
      if (val.length >= 4) {
        this.dataTableHeight = "10rem";
      } else if (val.length >= 2) {
        this.dataTableHeight = "7rem";
      } else {
        this.dataTableHeight = "5rem";
      }
    },
  },

  methods: {
    ...mapActions({
      findProveedoresByFilters: "proveedores/findProveedoresByFilters",
      setAlert: "user/setAlert",
    }),
    selectProveedor() {
      this.$emit("toggleModalBusquedaProv");
      this.$emit("findProveedor", this.proveedorSelected[0]);
    },
    closeModal() {
      this.$emit("toggleModalBusquedaProv");
    },
    // metodos grupos familiares filter
    async filterProveedores() {
      if (
        this.proveedorSearchNombre != null ||
        this.proveedorSearchCodigo != null ||
        this.proveedorSearchCuit != null
      ) {
        this.loading = true;
        this.customizeFiltersApplied();
        const data = {
          cuit:
            this.proveedorSearchCuit != null
              ? this.proveedorSearchCuit.replaceAll("-", "")
              : this.proveedorSearchCuit,
          codigo: this.proveedorSearchCodigo,
          nombreFantasia: this.proveedorSearchNombre,
        };
        try {
          this.filterApplied = true;
          const response = await this.findProveedoresByFilters(data);
          this.proveedoresResult = response;
          this.banderaProveedorSelected
            ? (this.proveedorSelected = this.proveedoresResult)
            : [];
          this.loading = false;
        } catch {
          this.loading = false;
        }
      } else {
        this.setAlert({
          type: "warning",
          message: "Ingrese al menos un parámetro de búsqueda",
        });
      }
    },
    customizeFiltersApplied() {
      // lo quito y agrego manualmente ya que el componente de filtros lo toma como undefined
      this.filtersApplyed = [];

      //  chequea si ya selecciono un afiliado en el data table
      if (this.proveedorSelected.length > 0) {
        this.filtersApplyed.splice(0, 1, {
          key: "provNom",
          label: "Proveedor",
          model: this.proveedorSelected[0].provNom.toUpperCase(),
        });
        // aca chequea si todavia no selecciono afiliado pero escondio el modal de los filtros
      } else {
        if (this.proveedorSearchCuit) {
          this.filtersApplyed.splice(0, 1, {
            key: "cuit",
            label: "CUIT",
            model: this.proveedorSearchCuit,
          });
        }
        if (this.proveedorSearchCodigo) {
          this.filtersApplyed.splice(1, 1, {
            key: "codigo",
            label: "Código",
            model: this.proveedorSearchCodigo,
          });
        }

        if (this.proveedorSearchNombre) {
          this.filtersApplyed.splice(2, 1, {
            key: "nombre",
            label: "Nombre",
            model: this.proveedorSearchNombre.toUpperCase(),
          });
        }
      }
    },
    selectedProveedor() {
      if (this.proveedorSelected.length > 0) {
        this.provId = this.proveedorSelected[0].id;
        this.banderaProveedorSelected = false;
      }
    },
  },
};
</script>

<style scoped>
::v-deep .theme--light.v-input input {
  color: rgb(56, 56, 56);
}
::v-deep .v-input__slot {
  cursor: context-menu !important;
}
</style>
